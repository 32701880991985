/*
 * !!! THIS IS A GENERATED FILE – DO NOT EDIT !!!
 */

import React from "react";

export const ArrowRight24 = React.memo<React.SVGProps<SVGSVGElement>>((props) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path fill="currentColor" d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z" />
  </svg>
));

export const __descriptor_ArrowRight = {
  name: "ArrowRight",
  instances: [{ size: 24, Component: ArrowRight24 }],
} as const;
