import React from "react";
import * as TUI from "theme-ui";
import { LazyImage } from "../Image/Image";
import { LayoutSection } from "../layout";

const Root = TUI.Box;

export interface Props extends React.ComponentPropsWithoutRef<typeof Root> {
  title?: string;
  backgroundImage?: string;
  customData?: Record<string, $FixMe>;
}

function PageCoverHome(props: Props, ref: React.Ref<HTMLDivElement>) {
  const { title, backgroundImage, sx, customData, ...rest } = props;

  const params =
    Object.keys(customData || {}).length !== 0
      ? customData
      : {
          "fp-x": 0.2,
          "fp-y": 0.4,
          "fp-z": 1.1,
        };

  return (
    <Root
      ref={ref}
      {...rest}
      sx={{
        position: "relative",
        mt: [4, 4, 5],
        borderBottom: (theme) => ["none", `1px solid ${theme.colors.grayDark}`],
        ...sx,
      }}
    >
      <LayoutSection sx={{ gap: [0, 3] }}>
        <TUI.Grid
          gap={0}
          columns={["1fr", "minMax(200px, 25%) 1fr 1fr"]}
          sx={{
            bg: "white",
          }}
        >
          <TUI.Box
            sx={{
              gridRow: [2, 1],
              gridColumn: ["1 / -1", "2 / -1"],
              position: "relative",
              borderBottom: (theme) => [`1px solid ${theme.colors.grayDark}`, "none"],
            }}
          >
            <TUI.AspectRatio ratio={1.25} sx={{ bg: "#ccc" }}>
              <LazyImage src={backgroundImage} params={{ ar: 1.25, ...params }} sizes="auto" strategy="blur" />
            </TUI.AspectRatio>
            <TUI.Box
              sx={{
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "auto 100%",
                backgroundPosition: "-1px -1px",
                backgroundImage: `url(${require("../../../public/icons/bg-shape.svg")})`,
                zIndex: 1,
              }}
            />
          </TUI.Box>

          <TUI.Box
            sx={{
              gridRow: [3, 1],
              gridColumn: ["1 / -1"],
              px: [3, 5],
              zIndex: 1,
              position: "relative",
            }}
          >
            <TUI.Box
              sx={{
                width: ["100%", "50%"],
                position: "relative",
              }}
            >
              <TUI.Heading
                as="h1"
                variant="f1_bold"
                sx={{
                  pt: [4, 5, 6],
                  pb: [0, 5],
                  textTransform: "uppercase",
                  letterSpacing: 2.6,
                }}
              >
                {title}
              </TUI.Heading>
            </TUI.Box>
          </TUI.Box>
        </TUI.Grid>
      </LayoutSection>
    </Root>
  );
}

export default React.forwardRef(PageCoverHome);
