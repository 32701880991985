/*
 * !!! THIS IS A GENERATED FILE – DO NOT EDIT !!!
 */

import React from "react";

export const Linkedin24 = React.memo<React.SVGProps<SVGSVGElement>>((props) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path fill="currentColor" d="M15.15 8.4a5.83 5.83 0 00-5.85 5.82v5.88a.9.9 0 00.9.9h2.1a.9.9 0 00.9-.9v-5.88a1.941 1.941 0 012.15-1.93 2 2 0 011.75 2v5.81a.9.9 0 00.9.9h2.1a.9.9 0 00.9-.9v-5.88a5.83 5.83 0 00-5.85-5.82zm-8.55.9H3.9a.9.9 0 00-.9.9v9.9a.9.9 0 00.9.9h2.7a.9.9 0 00.9-.9v-9.9a.9.9 0 00-.9-.9zM5.25 7.5a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" />
  </svg>
));

export const __descriptor_Linkedin = {
  name: "Linkedin",
  instances: [{ size: 24, Component: Linkedin24 }],
} as const;
