import { Footer } from "@/components/Footer";
import { Header } from "@/components/Header";
import { PageCoverHome } from "@/components/PageCover";
import * as GQL from "@/gql";
import { mergeSeoTags } from "@/lib/dato";
import * as React from "react";
import * as TUI from "theme-ui";
import { PageContext } from "../../contexts/Page";
import { column, SectionContainer } from "../SectionContainer";
import { NewsAndEvents, PreFooter, Teasers } from "./internal";

const Root = TUI.Box;

export interface Props extends React.ComponentPropsWithoutRef<typeof Root> {
  homePage: GQL.HomePageFragment;
  posts: GQL.MostRecentNewsAndEventsQuery;
}

function HomePage(props: Props, ref: React.Ref<HTMLDivElement>) {
  const { homePage, posts, sx, ...rest } = props;

  const alternates = ["de", "fr", "it", "en"].map((d) => ({ hrefLang: d, href: `${process.env.URL}/${d}` }));

  const seoTags = mergeSeoTags(
    homePage._seoMetaTags,
    !!homePage.seo?.description,
    !!homePage.seo?.image,
    homePage.seoImage?.url,
    null,
  );

  /*
   * XXX: The PageContext variant is wrong, should be HomePage.
   */

  return (
    <PageContext variant="ContentPage" id={homePage.id}>
      <Root ref={ref} {...rest} sx={{ ...sx }}>
        <TUI.Box sx={{ minHeight: "90vh" }}>
          <Header ref={ref} noindex={false} metaTags={seoTags} alternates={alternates} />

          <PageCoverHome title={homePage.title!} backgroundImage={homePage.cover?.url} mb={[2, 5]} />

          <SectionContainer>
            <TUI.Box sx={{ gridColumn: column.full }}>
              <TUI.Text variant="f4" mb={5}>
                {homePage.description}
              </TUI.Text>

              <Teasers homePage={homePage} />

              <TUI.Text variant="f3_bold" mb={[2, 4]}>
                News &amp; Events
              </TUI.Text>

              <NewsAndEvents posts={posts} kvMainContentPage={homePage.kaufleuteContentPage} />
            </TUI.Box>
          </SectionContainer>
        </TUI.Box>

        <PreFooter homePage={homePage} mt={[5, 6]} />

        <Footer />
      </Root>
    </PageContext>
  );
}

export default React.forwardRef(HomePage);
