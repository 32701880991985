import * as GQL from "@/gql";
import { ArrowRight24 } from "@/icons";
import { contentPageLinkProps } from "@/lib/links";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { Image } from "react-datocms";
import * as TUI from "theme-ui";

const Root = TUI.Grid;

interface Props extends React.ComponentPropsWithoutRef<typeof Root> {
  homePage: GQL.HomePageFragment;
}

function Teasers(props: Props, ref: React.Ref<HTMLDivElement>) {
  const { homePage, sx, ...rest } = props;
  const { locale } = useRouter();

  const teasers = useMemo(() => {
    return getHomePageTeaserCards(homePage.teaserCards);
  }, [homePage.teaserCards, getHomePageTeaserCards]);

  return (
    <Root ref={ref} {...rest} columns={["1fr", "1fr 1fr"]} gap={[3, 4]} mb={5} sx={sx}>
      {teasers.map((d, i) => (
        <TUI.Grid
          key={i}
          sx={{
            minHeight: 400,
            gridTemplateRows: "auto 1fr",
            border: (theme) => `1px solid ${theme.colors.grayDark}`,
          }}
        >
          {d.cover?.responsiveImage && <Image data={d.cover.responsiveImage} />}

          <TUI.Flex
            sx={{
              flexDirection: "column",
              p: [3, 3, 4],
            }}
          >
            <TUI.Heading variant="f4_bold">{d.title}</TUI.Heading>
            <TUI.Text variant="f5" my={3}>
              {d.description}
            </TUI.Text>

            <TUI.Box mt="auto">
              {d.links.map((l, i) => (
                <Link key={i} {...contentPageLinkProps(locale, { id: l?.id })} passHref legacyBehavior>
                  <TUI.Link
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      py: 1,
                      textDecoration: "none",
                      color: (theme) => theme.colors.text,

                      "&:hover > [data-id='teaser-arrow']": {
                        transform: "translateX(0.5em)",
                      },
                    }}
                  >
                    <TUI.Text variant="f5_bold" mr={1}>
                      {l.pageName}
                    </TUI.Text>
                    <TUI.Box
                      data-id="teaser-arrow"
                      sx={{ transition: ".2s all", color: homePage.domain?.hex || "accent" }}
                    >
                      <ArrowRight24 />
                    </TUI.Box>
                  </TUI.Link>
                </Link>
              ))}
            </TUI.Box>
          </TUI.Flex>
        </TUI.Grid>
      ))}
    </Root>
  );
}

export default React.forwardRef(Teasers);

function getHomePageTeaserCards(teaserCards: GQL.HomePageFragment["teaserCards"]) {
  return teaserCards.map((d) => ({
    ...d,
    links: d.links.filter((l) => l.pageName && l.slug),
  }));
}
