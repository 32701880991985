/*
 * This context is used by ContextBlock so they can adjust style based on where
 * the content block is used.
 */

import React from "react";

interface Value {
  variant: "ContentPage" | "Aside" | "BlogPost" | "Accordion" | "Team";
}

const Context = React.createContext<Value>({
  variant: "ContentPage",
});

export function ContentBlockContext(props: React.PropsWithChildren<Value>) {
  const { children, ...value } = props;
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useContentBlockContext = () => React.useContext(Context);
