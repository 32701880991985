import * as GQL from "@/gql";
import { useMessages } from "@/lib/intl";
import { contentPageLinkProps, slugLinkProps } from "@/lib/links";
import { pageLinkProps } from "@/lib/pageLinkProps";
import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { Image } from "react-datocms";
import stripMarkDown from "remove-markdown-and-html";
import * as TUI from "theme-ui";

const Root = TUI.Grid;

interface Props extends React.ComponentPropsWithoutRef<typeof Root> {
  posts: GQL.MostRecentNewsAndEventsQuery;
  kvMainContentPage: GQL.HomePageFragment["kaufleuteContentPage"];
}

function NewsAndEvents(props: Props, ref: React.Ref<HTMLDivElement>) {
  const { posts, kvMainContentPage, sx, ...rest } = props;
  const { locale } = useRouter();
  const { t } = useMessages();

  const preparedPosts = useMemo(
    () => preparePosts(locale, posts, kvMainContentPage),
    [posts, kvMainContentPage, preparePosts],
  );

  return (
    <Root ref={ref} {...rest} columns={["1fr", "repeat(3, 1fr)"]} gap={[2, 3]} mb={5} sx={sx}>
      {preparedPosts.map((d, i) => (
        <Link key={i} {...d.linkProps} passHref legacyBehavior>
          <TUI.Link sx={{ textDecoration: "none", color: (theme) => theme.colors.text }}>
            <TUI.Grid sx={{ gridTemplateRows: "auto 1fr" }}>
              {d.cover?.responsiveImage && (
                <TUI.Box
                  sx={{
                    border: (theme) => `1px solid ${theme.colors.grayDark}`,
                  }}
                >
                  <Image data={d.cover.responsiveImage} />
                </TUI.Box>
              )}

              <TUI.Flex sx={{ alignItems: "center", gap: 1 }}>
                <TUI.Text variant="f7">
                  {new Date(d.createdAt).toLocaleDateString(locale, {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  })}
                  {" |"}
                </TUI.Text>
                <TUI.Text variant="f7" sx={{ textTransform: "uppercase", fontWeight: 700 }}>
                  {isTranslatable(d.type) ? t(d.type) : d.label}
                </TUI.Text>
              </TUI.Flex>

              <TUI.Heading variant="f4_bold">{d.title}</TUI.Heading>
              <TUI.Divider sx={{ width: "25%", borderBottom: (theme) => `3px solid ${theme.colors.text}`, my: 0 }} />
              <TUI.Text variant="f6" mt={1}>
                {d.teaser}
              </TUI.Text>
            </TUI.Grid>
          </TUI.Link>
        </Link>
      ))}
    </Root>
  );
}

export default React.forwardRef(NewsAndEvents);

/**
 * KV tag name comes from the kaufleuteContentPage defined on the Home Page in Dato.
 * "mediaRelease" | "mediaPress" are defined translations
 */
const isTranslatable = (type: string): type is "blog" | "event" =>
  ["blog", "event", "mediaRelease", "mediaPress"].includes(type);

type NewsOrEventType = "blog" | "event" | "kv" | "news" | "mediaRelease" | "mediaPress";
interface NewsOrEvent {
  type: NewsOrEventType;
  label?: string;
  title: string;
  teaser: string;
  linkProps: LinkProps & { as: string };
  cover?: GQL.ResponsiveImageFragmentFragment;
  createdAt: string;
}

/**
 * @FIXME
 * keep in sync with dato `announcementType` field restrictions
 * https://cyp-website.admin.datocms.com/admin/item_types/1851726/fields/9334309/edit
 */

type AnnouncementType = "event" | "news" | "media-release" | "media-press";

const preparePosts = (
  locale: string,
  posts: GQL.MostRecentNewsAndEventsQuery,
  kvMainContentPage: GQL.HomePageFragment["kaufleuteContentPage"],
) => {
  return posts.homePage!.recentNews.map((d): NewsOrEvent => {
    switch (d.__typename) {
      case "BlogPostRecord": {
        return {
          type: "blog",
          title: d.title || "",
          teaser: d.teaser || "",
          linkProps: pageLinkProps(locale, "BlogPost", d.id),
          cover: (d.homePageTeaserCoverImage || d.coverImage)!,
          createdAt: d.createdAt,
        };
      }

      case "KvPostRecord": {
        return {
          type: "kv",
          label: kvMainContentPage!.title || "",
          title: d.title || "",
          teaser: d.teaser || "",
          linkProps: pageLinkProps(locale, "KvPost", d.id),
          cover: d.homePageTeaserCoverImage!,
          createdAt: d.createdAt,
        };
      }

      case "AnnouncementRecord": {
        const announcementType = d.announcementType! as AnnouncementType;
        const slug = d._allSlugLocales?.find((x) => x?.locale === locale)?.value;

        if (!slug) {
          throw new Error("Announcement must have a slug");
        }

        const announcementToLabeltype: Record<
          AnnouncementType,
          { key: NewsOrEventType; link: LinkProps & { as: string } }
        > = {
          event: { key: "event", link: slugLinkProps(locale, { ...posts.eventsContentPage!, value: slug }) },
          news: {
            key: "news",
            link: d.internalLink
              ? contentPageLinkProps(locale, d.internalLink)
              : contentPageLinkProps(locale, { ...posts.newsContentPage!, hash: slug }),
          },
          "media-release": {
            key: "mediaRelease",
            link: contentPageLinkProps(locale, { ...posts.mediaContentPage!, hash: "mediaRelease" }),
          },
          "media-press": {
            key: "mediaPress",
            link: contentPageLinkProps(locale, { ...posts.mediaContentPage!, hash: "mediaPress" }),
          },
        };

        const config = announcementToLabeltype[announcementType];
        return {
          type: config.key,
          title: d.title || "",
          label: config.key,
          teaser: d.summary ? stripMarkDown(d.summary) : "",
          linkProps: config.link,
          cover: d.coverImage || posts.fallbackImage?.globalSeo?.fallbackSeo?.image!,
          createdAt: d.date || "",
        };
      }
    }
  });
};
