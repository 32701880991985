/*
 * !!! THIS IS A GENERATED FILE – DO NOT EDIT !!!
 */

import React from "react";

export const Xing24 = React.memo<React.SVGProps<SVGSVGElement>>((props) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path fill="currentColor" d="M5.99 16.817c.367 0 .682-.222.943-.667 2.39-4.237 3.633-6.442 3.73-6.617l-2.38-4.15c-.252-.435-.57-.653-.958-.653H3.857c-.223 0-.377.073-.464.218-.107.145-.102.324.014.537l2.336 4.048c.01.01.01.015 0 .015l-3.67 6.5a.507.507 0 000 .522.485.485 0 00.45.247H5.99zM21.907 0h-3.496c-.378 0-.687.218-.929.653-4.962 8.803-7.53 13.36-7.705 13.668l4.919 9.026c.232.435.551.653.957.653h3.468c.213 0 .368-.073.465-.218.096-.165.091-.344-.015-.537l-4.875-8.924v-.014L22.358.755c.106-.203.106-.382 0-.537C22.27.073 22.12 0 21.908 0z" />
  </svg>
));

export const __descriptor_Xing = {
  name: "Xing",
  instances: [{ size: 24, Component: Xing24 }],
} as const;
