import * as TUI from "theme-ui";
import { useEffect, useState } from "react";
import Imgix, { buildURL, SharedImgixAndSourceProps } from "react-imgix";

export function getColor(src: string, fallback = "#cccccc") {
  const [color, setColor] = useState(fallback);
  useEffect(() => {
    fetch(buildURL(src, { w: 100, palette: "json" }))
      .then((d) => d.json())
      .then((d) => {
        const vibrant: string = d.dominant_colors?.vibrant?.hex ?? fallback;
        setColor(vibrant);
      })
      .catch((e) => {
        console.warn(`Failed to fetch: ${src}`, e);
      });
  }, []);
  return color;
}

function getDimensions(src: string) {
  const [dimensions, setDimensions] = useState<{ w?: number; h?: number }>({
    w: undefined,
    h: undefined,
  });
  useEffect(() => {
    fetch(buildURL(src, { fm: "json" }))
      .then((d) => d.json())
      .then((d) => {
        setDimensions({ w: d.PixelWidth, h: d.PixelHeight });
      })
      .catch((e) => {
        console.warn(`Failed to fetch: ${src}`, e);
      });
  }, []);
  return dimensions;
}

type ImgixParams = SharedImgixAndSourceProps["imgixParams"];

export const LazyImage = ({
  background,
  src,
  alt,
  strategy = "color",
  sizes = "(min-width: 36em) 33.3vw, 100vw",
  params = {},
  sx = {},
  debug,
}: {
  background?: string;
  src?: string;
  alt?: string;
  strategy?: "blur" | "color";
  sizes?: string;
  params?: ImgixParams;
  sx?: TUI.SxStyleProp;
  debug?: boolean;
}) => {
  if (!src) {
    return null;
  }

  const cropMode = Object.keys(params).find((d) => d.startsWith("fp-"))
    ? "focalpoint"
    : "faces,edges";

  const shared: ImgixParams = {
    auto: "format",
    fit: "crop",
    ...params,
    crop: cropMode,
  };

  const final = buildURL(src, { ...shared });
  const bg = background || getColor(src);

  const restrictSize = !!(
    params.w ||
    params["max-w"] ||
    params.ar === "source"
  );

  const dimensions = params.ar !== "source" ? undefined : getDimensions(src);

  if (debug) {
    console.log("URL: ", final);
    console.table(shared);
    console.log(dimensions);
  }

  return (
    <TUI.Box
      as="span"
      sx={{
        display: "block",
        maxWidth: "100%",
        "& img": {
          width: restrictSize ? dimensions?.w || "auto" : "100%",
          bg,
          transition: ".2s background-color",
          ...sx,
        },
        "& img.lazyloaded": {
          bg: "transparent",
        },
      }}
    >
      <Imgix
        className="lazyload"
        src={final}
        sizes={sizes}
        attributeConfig={{
          src: "data-src",
          srcSet: "data-srcset",
          sizes: "data-sizes",
        }}
        htmlAttributes={{
          alt,
          src:
            strategy === "blur"
              ? buildURL(src, { ...shared, blur: 500 })
              : "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==",
        }}
      />
    </TUI.Box>
  );
};
