/*
 * !!! THIS IS A GENERATED FILE – DO NOT EDIT !!!
 */

import React from "react";

export const Facebook24 = React.memo<React.SVGProps<SVGSVGElement>>((props) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path fill="currentColor" d="M17 3.5a.5.5 0 00-.5-.5H14a4.77 4.77 0 00-5 4.5v2.7H6.5a.5.5 0 00-.5.5v2.6a.5.5 0 00.5.5H9v6.7a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-6.7h2.62a.5.5 0 00.49-.37l.72-2.6a.5.5 0 00-.48-.63H13V7.5a1 1 0 011-.9h2.5a.5.5 0 00.5-.5V3.5z" />
  </svg>
));

export const __descriptor_Facebook = {
  name: "Facebook",
  instances: [{ size: 24, Component: Facebook24 }],
} as const;
