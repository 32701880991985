import { LazyImage } from "@/components/Image/Image";
import { Intersperse } from "@/components/Intersperse";
import * as GQL from "@/gql";
import { contentPageLinkProps } from "@/lib/links";
import Link from "next/link";
import { useRouter } from "next/router";
import * as React from "react";
import * as TUI from "theme-ui";

const Root = TUI.Box;

export interface Props extends React.ComponentPropsWithoutRef<typeof Root> {
  homePage: GQL.HomePageFragment;
}

function PreFooter(props: Props, ref: React.Ref<HTMLDivElement>) {
  const { locale } = useRouter();

  const { homePage, sx, ...rest } = props;

  return (
    <Root ref={ref} {...rest} sx={{ bg: "#f4f4f4", ...sx }}>
      <TUI.Box
        sx={{
          minWidth: "280px",
          maxWidth: "1152px",
          mx: "auto",
          display: "flex",
          flexDirection: ["column", "row"],
          alignItems: "stretch",
          justifyContent: "stretch",
          minHeight: "186px",
        }}
      >
        <Intersperse separator={<TUI.Box sx={{ width: 4, flex: "4px 0 0", bg: "white" }} />}>
          {homePage.preFooter.map((el, i) => (
            <Link
              key={i}
              passHref
              {...contentPageLinkProps(locale, el.contentPage!)}
              legacyBehavior>
              <TUI.Link
                sx={{
                  flexBasis: "100%",
                  display: "flex",
                  alignItems: "center",
                  p: 4,
                  color: "inherit",
                  textDecoration: "none",
                  ":first-of-type": {
                    pl: [4, 2, 2, 2, 0],
                  },
                  ":last-of-type": {
                    pr: [4, 2, 2, 2, 0],
                  },
                }}
              >
                <LazyImage src={el.icon?.url} sizes="auto" strategy="blur" sx={{ height: "80px", width: "80px" }} />

                <TUI.Box sx={{ ml: 3 }}>
                  <TUI.Text variant="f4_bold">{el.title}</TUI.Text>
                  <TUI.Text variant="f5">{el.subtitle}</TUI.Text>
                </TUI.Box>
              </TUI.Link>
            </Link>
          ))}
        </Intersperse>
      </TUI.Box>
    </Root>
  );
}

export default React.forwardRef(PreFooter);
