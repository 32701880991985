/*
 * This context is wrapped around each page. It provides details about what kind
 * of page it is (regular content page vs blog post page), what color scheme
 * the page uses etc.
 *
 * The Page Context wraps the children in a top-level Context Block Context.
 */

import React from "react";
import { ContentBlockContext } from "./ContentBlock";

interface Value {
  variant: "ContentPage" | "BlogPost";
  id: string;
}

const Context = React.createContext<Value>({
  variant: "ContentPage",
  id: "0"
});

export function PageContext(props: React.PropsWithChildren<Value>) {
  const { children, ...value } = props;

  return (
    <Context.Provider value={value}>
      <ContentBlockContext variant={value.variant}>
        {children}
      </ContentBlockContext>
    </Context.Provider>
  );
}

export const usePageContext = () => React.useContext(Context);
